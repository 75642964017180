<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Assign E or Z to each of the following molecules:</p>

      <table>
        <thead>
          <tr>
            <th>Molecule</th>
            <th>E</th>
            <th>Z</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="'pt-1-' + row.letter">
            <td>
              {{ row.letter }}
              <v-img :src="row.img" style="max-width: 170px" />
            </td>
            <td style="width: 100px">
              <v-radio-group v-model="inputs[row.input]" row>
                <v-radio key="E" value="E" style="display: block; margin: auto" />
              </v-radio-group>
            </td>
            <td style="width: 100px">
              <v-radio-group v-model="inputs[row.input]" row>
                <v-radio key="Z" value="Z" style="display: block; margin: auto" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemLC241PS9Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
      },
      rows: [
        {img: '/img/assignments/LC241/PS9-img1.png', letter: 'A)', input: 'input1'},
        {img: '/img/assignments/LC241/PS9-img2.png', letter: 'B)', input: 'input2'},
        {img: '/img/assignments/LC241/PS9-img3.png', letter: 'C)', input: 'input3'},
        {img: '/img/assignments/LC241/PS9-img4.png', letter: 'D)', input: 'input4'},
        {img: '/img/assignments/LC241/PS9-img5.png', letter: 'E)', input: 'input5'},
        {img: '/img/assignments/LC241/PS9-img6.png', letter: 'F)', input: 'input6'},
        {img: '/img/assignments/LC241/PS9-img7.png', letter: 'G)', input: 'input7'},
        {img: '/img/assignments/LC241/PS9-img8.png', letter: 'H)', input: 'input8'},
        {img: '/img/assignments/LC241/PS9-img9.png', letter: 'I)', input: 'input9'},
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
